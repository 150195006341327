import React from 'react';

import { SVGIconComponent } from '../types';

export const paymentCard: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="m20 4h-16c-1.11 0-1.99 0.89-1.99 2l-0.01 12c0 1.11 0.89 2 2 2h16c1.11 0 2-0.89 2-2v-12c0-1.11-0.89-2-2-2zm0 14h-16v-6h16v6zm0-10h-16v-2h16v2z" />
  </svg>
);
