export enum SupportedFontTypes {
  'woff' = 'woff',
  'woff2' = 'woff2',
  'otf' = 'otf',
}

export enum SupportedFonts {
  FlameBold,
  FlameRegular,
  FlameSans,
  ChickenSansBold,
  ChickenSansRegular,
  SofiaProBlack,
  SofiaProBold,
  SofiaProRegular,
}

export interface IFontSource {
  source: SupportedFonts;
  type: SupportedFontTypes.otf | SupportedFontTypes.woff | SupportedFontTypes.woff2;
}

export interface IFontFace {
  name: string;
  sources: IFontSource[];
  weight: string;
  style: string;
  display: string;
}
