/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Radio as NbRadio } from 'native-base';

import { IRadioComponentType, IRadioProps } from './types';

const RadioMain = ({ ...props }: IRadioProps) => <NbRadio {...props} />;

const RadioTemp: any = RadioMain;
RadioTemp.Group = NbRadio.Group;

const Radio = RadioTemp as IRadioComponentType;

export { Radio };
