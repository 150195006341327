import React from 'react';

import { Icon } from 'native-base';
import { Path, Rect } from 'react-native-svg';

import { ISVGProps } from './types';

export const plkGiftCard = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Rect y="4" width="24" height="16" rx="2" fill="#FF7D00" />
    <Rect
      x="0.5"
      y="4.5"
      width="23"
      height="15"
      rx="1.5"
      stroke="black"
      strokeOpacity="0.1"
      fill="#FF7D00"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.56006 10.2756L8.48337 11.399C8.48337 11.399 8.97706 10.6676 9.5857 11.1583C10.053 11.5351 9.91043 12.2158 9.79423 12.775C9.75906 12.944 9.72577 13.1017 9.7132 13.2366C9.64223 13.9749 9.82249 14.572 10.1171 15.0463C10.4921 15.6498 11.3626 16.1519 12.2972 16.3292C12.3688 16.3425 12.4373 16.3526 12.5051 16.3615L12.6025 17.4H13.3066L13.4014 16.3856C15.0176 16.2545 17.3039 14.6607 16.2863 11.9765C15.8184 10.7417 14.8266 8.89776 14.8266 8.89776L14.8379 8.8946C15.2305 8.79391 15.3065 8.28038 14.848 8.00493L15.1758 7.69908L14.6966 7.50088L14.8756 6.84044L13.8461 7.2514L13.8474 7.25013L13.8486 7.24886C13.8486 7.24886 13.588 6.7005 13.191 6.69606C12.8569 6.69227 12.6992 6.93605 12.6992 6.93605C12.6992 6.93605 12.61 6.6853 12.3814 6.61691C12.1528 6.54852 11.7797 6.68277 11.7326 7.10132C11.6792 7.57054 12.0554 7.81179 12.1791 7.85169C12.1905 7.85549 12.2024 7.85929 12.2143 7.86308C12.3588 7.91184 12.5617 7.98023 12.9542 7.81306C12.9536 8.03279 12.953 8.39942 12.9523 8.77302C12.9517 9.13205 12.9511 9.49742 12.9505 9.74311C12.9505 9.83619 12.9511 9.93751 12.9523 10.0445C12.9593 10.6853 12.9674 11.5294 12.7306 12.0195C12.4536 12.5913 12.1383 12.8294 11.7206 12.78C11.2539 12.7249 11.0944 12.2842 11.2565 11.4667C11.4185 10.6492 11.5554 9.83556 10.8752 9.22957C10.195 8.62358 8.97204 8.55709 7.56006 10.2756ZM13.9353 7.65032C14.0402 7.59587 14.1746 7.64906 14.2349 7.76747C14.2952 7.88651 14.2588 8.02709 14.1539 8.08091C14.049 8.13473 13.9146 8.08218 13.8543 7.96313C13.794 7.84472 13.8298 7.70415 13.9353 7.65032Z"
      fill="white"
    />
  </Icon>
);
