import React from 'react';

import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

import { ISVGProps } from './types';

export const next = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Path d="M7.5 20.23L9.30459 22L19.5 12L9.30459 2L7.5 3.77L15.8908 12L7.5 20.23Z" />
  </Icon>
);
