import React from 'react';

import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

import { ISVGProps } from './types';

export const thOrderFilled = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.26682 3.09511C7.10111 3.42752 7.34287 3.81818 7.7143 3.81818H16.9748C17.3462 3.81818 17.588 3.42753 17.4223 3.09511L17.0144 2.27693C16.9298 2.10724 16.7565 2 16.5669 2H8.12216C7.93256 2 7.75927 2.10724 7.67468 2.27693L7.26682 3.09511ZM6 5.37728C6 5.0183 6.29101 4.72728 6.65 4.72728H18.0391C18.3981 4.72728 18.6891 5.0183 18.6891 5.37728V5.89546C18.6891 6.25445 18.3981 6.54546 18.0391 6.54546H6.65C6.29101 6.54546 6 6.25445 6 5.89546V5.37728ZM6.94203 8.1869C6.89242 7.79838 7.19513 7.45456 7.58679 7.45456H17.1024C17.494 7.45456 17.7967 7.79838 17.7471 8.18689L16.0557 21.4323C16.0143 21.7569 15.7381 22 15.411 22H9.27817C8.95102 22 8.67485 21.7569 8.63341 21.4323L6.94203 8.1869Z"
    />
  </Icon>
);
