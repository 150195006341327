/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Checkbox as NbCheckbox } from 'native-base';

import { Icon } from '~/components';

import { ICheckboxComponentType, ICheckboxProps } from './types';

const CheckboxMain = ({ ...props }: ICheckboxProps) => (
  <NbCheckbox {...props} icon={<Icon icon={props.isNullVariation ? 'remove' : 'check'} />} />
);
const CheckTemp: any = CheckboxMain;
CheckTemp.Group = NbCheckbox.Group;

const Checkbox = CheckTemp as ICheckboxComponentType;

export { Checkbox };
