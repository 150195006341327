import React from 'react';

import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

import { ISVGProps } from './types';

export const thOffer = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6843 10.4431L18.6843 12.8286C18.6843 13.2776 18.5059 13.7082 18.1884 14.0257L9.70558 22.5085C9.37499 22.8391 8.839 22.8391 8.50842 22.5085L1.32545 15.3256C0.994868 14.995 0.994868 14.459 1.32546 14.1284L9.80828 5.6456C10.1258 5.32809 10.5564 5.14972 11.0054 5.14972H13.3909C13.5184 4.14537 13.9679 3.17321 14.7392 2.40189C16.5874 0.553698 19.5839 0.553698 21.4321 2.40189C23.2803 4.25009 23.2803 7.24661 21.4321 9.0948C20.6608 9.8661 19.6887 10.3157 18.6843 10.4431ZM11.0054 6.14972L17.6843 6.14972V9.45947C17.405 9.42948 17.1287 9.36808 16.8608 9.27527C17.0505 8.64509 16.8964 7.93349 16.3985 7.43553C15.6774 6.71442 14.5082 6.71442 13.7871 7.43553C13.066 8.15664 13.066 9.32579 13.7871 10.0469C14.4778 10.7376 15.5795 10.7667 16.3049 10.1343C16.7487 10.3143 17.2142 10.4241 17.6843 10.464V12.8286C17.6843 13.0124 17.6113 13.1886 17.4813 13.3186L9.107 21.6929L2.14109 14.727L10.5154 6.35271C10.6454 6.22274 10.8216 6.14972 11.0054 6.14972ZM18.6843 9.43297C19.4317 9.3123 20.1487 8.964 20.725 8.3877C22.1827 6.93002 22.1827 4.56667 20.725 3.109C19.2674 1.65133 16.904 1.65133 15.4463 3.109C14.8701 3.68527 14.5217 4.40233 14.4011 5.14972H17.8378C18.3053 5.14972 18.6843 5.52872 18.6843 5.99624L18.6843 9.43297ZM15.9372 8.80141C15.9539 8.56517 15.872 8.32325 15.6914 8.14264C15.5108 7.96202 15.2688 7.88008 15.0326 7.89683C15.154 8.06896 15.2919 8.23329 15.4463 8.3877C15.6006 8.54192 15.7649 8.67982 15.9372 8.80141ZM14.2687 8.54722C14.4094 8.73872 14.5663 8.92188 14.7392 9.0948C14.9123 9.26785 15.0954 9.4247 15.2868 9.56534C15.011 9.62996 14.7092 9.55478 14.4942 9.3398C14.2792 9.12481 14.2041 8.82295 14.2687 8.54722Z"
    />
  </Icon>
);
