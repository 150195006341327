import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';

import * as SVGs from '~/svgs';

import { commonIcons } from './common-icons';
import { IIcon } from './types';

interface IIconMaterialInfo {
  lib: typeof MaterialCommunityIcons | typeof MaterialIcons;
  name: string;
}
type TIconCustomInfoKeys = keyof typeof commonIcons;

interface IIconCustomInfo {
  lib: 'custom';
  name: TIconCustomInfoKeys;
}

type TIconMap = Record<IIcon, IIconCustomInfo | IIconMaterialInfo>;

/* eslint-disable max-lines */
export const iconMap: TIconMap = {
  accessible: {
    lib: MaterialIcons,
    name: 'accessible',
  },
  account: {
    lib: MaterialIcons,
    name: 'account-circle',
  },
  add: {
    lib: MaterialIcons,
    name: 'add',
  },
  addCard: {
    lib: 'custom',
    name: 'addCard',
  },
  amazonPay: {
    lib: 'custom',
    name: 'amazonPay',
  },
  amex: {
    lib: 'custom',
    name: 'amex',
  },
  applepay: {
    lib: 'custom',
    name: 'applepay',
  },
  back: {
    lib: 'custom',
    name: 'back',
  },
  bkAccount: {
    lib: 'custom',
    name: 'bkAccount',
  },
  bkCode: {
    lib: 'custom',
    name: 'bkCode',
  },
  bkCrown: {
    lib: 'custom',
    name: 'bkCrown',
  },
  bkCrownPass: {
    lib: 'custom',
    name: 'bkCrownPass',
  },
  bkDaytime: {
    lib: 'custom',
    name: 'bkDaytime',
  },
  bkOffers: {
    lib: 'custom',
    name: 'bkOffers',
  },
  bkOrder: {
    lib: 'custom',
    name: 'bkOrder',
  },
  bkRedeem: {
    lib: MaterialIcons,
    name: 'wallet-giftcard',
  },
  bkRewards: {
    lib: 'custom',
    name: 'bkRewards',
  },
  bkSaving: {
    lib: 'custom',
    name: 'bkSaving',
  },
  bkSunrise: {
    lib: 'custom',
    name: 'bkSunrise',
  },
  calendar: {
    lib: MaterialCommunityIcons,
    name: 'calendar-today',
  },
  camera: {
    lib: MaterialIcons,
    name: 'camera-alt',
  },
  cancel: {
    lib: MaterialIcons,
    name: 'close',
  },
  cart: {
    lib: MaterialIcons,
    name: 'shopping-bag',
  },
  cash: {
    lib: 'custom',
    name: 'cash',
  },
  catering: {
    lib: 'custom',
    name: 'catering',
  },
  collapse: {
    lib: MaterialIcons,
    name: 'keyboard-arrow-up',
  },
  check: {
    lib: MaterialIcons,
    name: 'check',
  },
  checkCircle: {
    lib: MaterialIcons,
    name: 'check-circle-outline',
  },
  curbside: {
    lib: 'custom',
    name: 'curbside',
  },
  defaultCard: {
    lib: MaterialIcons,
    name: 'payment',
  },
  delivery: {
    lib: MaterialIcons,
    name: 'delivery-dining',
  },
  diners: {
    lib: 'custom',
    name: 'diners',
  },
  discover: {
    lib: 'custom',
    name: 'discover',
  },
  drive: {
    lib: MaterialIcons,
    name: 'drive-eta',
  },
  driveThru: {
    lib: 'custom',
    name: 'driveThru',
  },
  edit: {
    lib: MaterialIcons,
    name: 'edit',
  },
  error: {
    lib: MaterialIcons,
    name: 'error-outline',
  },
  errorFill: {
    lib: MaterialIcons,
    name: 'error',
  },
  facebook: {
    lib: 'custom',
    name: 'facebook',
  },
  favorite: {
    lib: MaterialIcons,
    name: 'favorite-outline',
  },
  favoriteFill: {
    lib: MaterialIcons,
    name: 'favorite',
  },
  filters: {
    lib: 'custom',
    name: 'filters',
  },
  forward: {
    lib: 'custom',
    name: 'next',
  },
  googlePay: {
    lib: 'custom',
    name: 'googlePay',
  },
  help: {
    lib: MaterialIcons,
    name: 'help-outline',
  },
  home: {
    lib: MaterialIcons,
    name: 'home',
  },
  info: {
    lib: MaterialIcons,
    name: 'info-outline',
  },
  infoFill: {
    lib: MaterialIcons,
    name: 'info',
  },
  instagram: {
    lib: 'custom',
    name: 'instagram',
  },
  jcb: {
    lib: 'custom',
    name: 'jcb',
  },
  language: {
    lib: MaterialIcons,
    name: 'language',
  },
  laptop: {
    lib: MaterialIcons,
    name: 'laptop',
  },
  list: {
    lib: MaterialIcons,
    name: 'list',
  },
  location: {
    lib: MaterialIcons,
    name: 'location-on',
  },
  locked: {
    lib: MaterialIcons,
    name: 'lock',
  },
  map: {
    lib: MaterialCommunityIcons,
    name: 'map-outline',
  },
  mastercard: {
    lib: 'custom',
    name: 'mastercard',
  },
  masterpass: {
    lib: 'custom',
    name: 'masterpass',
  },
  menu: {
    lib: MaterialIcons,
    name: 'menu',
  },
  mobile: {
    lib: 'custom',
    name: 'mobile',
  },
  monitor: {
    lib: MaterialIcons,
    name: 'monitor',
  },
  more: {
    lib: MaterialIcons,
    name: 'more-horiz',
  },
  moreVertical: {
    lib: MaterialIcons,
    name: 'more-vert',
  },
  nearMe: {
    lib: MaterialIcons,
    name: 'near-me',
  },
  next: {
    lib: MaterialIcons,
    name: 'navigate-next',
  },
  offer: {
    lib: MaterialIcons,
    name: 'local-offer',
  },
  paypal: {
    lib: 'custom',
    name: 'paypal',
  },
  plkAccount: {
    lib: 'custom',
    name: 'plkAccount',
  },
  plkCatering: {
    lib: 'custom',
    name: 'plkCatering',
  },
  plkCateringDelivery: {
    lib: 'custom',
    name: 'plkCateringDelivery',
  },
  plkDelivery: {
    lib: 'custom',
    name: 'plkDelivery',
  },
  plkGiftCard: {
    lib: 'custom',
    name: 'plkGiftCard',
  },
  plkMenu: {
    lib: 'custom',
    name: 'plkMenu',
  },
  plkPickup: {
    lib: 'custom',
    name: 'plkPickup',
  },
  plkPickupFilled: {
    lib: 'custom',
    name: 'plkPickupFilled',
  },
  plkRewards: {
    lib: 'custom',
    name: 'plkRewards',
  },
  plkRewardsPoints: {
    lib: 'custom',
    name: 'plkRewardsPoints',
  },
  plkRewardsProgress: {
    lib: 'custom',
    name: 'plkRewardsProgress',
  },
  plkStore: {
    lib: 'custom',
    name: 'plkStore',
  },
  qr: {
    lib: MaterialIcons,
    name: 'qr-code',
  },
  qrScan: {
    lib: MaterialIcons,
    name: 'qr-code-scanner',
  },
  refresh: {
    lib: MaterialIcons,
    name: 'refresh',
  },
  remove: {
    lib: MaterialIcons,
    name: 'remove',
  },
  removeCircle: {
    lib: MaterialIcons,
    name: 'remove-circle-outline',
  },
  restaurant: {
    lib: MaterialIcons,
    name: 'restaurant',
  },
  search: {
    lib: MaterialIcons,
    name: 'search',
  },
  select: {
    lib: MaterialIcons,
    name: 'keyboard-arrow-down',
  },
  settings: {
    lib: MaterialIcons,
    name: 'settings',
  },
  sodiumFlag: {
    lib: 'custom',
    name: 'sodiumFlag',
  },
  starRate: {
    lib: MaterialIcons,
    name: 'star-border',
  },
  starRateFill: {
    lib: MaterialIcons,
    name: 'star',
  },
  store: {
    lib: MaterialIcons,
    name: 'store',
  },
  telephone: {
    lib: MaterialIcons,
    name: 'phone',
  },
  thAccount: {
    lib: 'custom',
    name: 'thAccount',
  },
  thCanadaFlag: {
    lib: 'custom',
    name: 'thCanadaFlag',
  },
  thHome: {
    lib: 'custom',
    name: 'thHome',
  },
  thMailReceived: {
    lib: 'custom',
    name: 'thMailReceived',
  },
  thOffer: {
    lib: 'custom',
    name: 'thOffer',
  },
  thOrder: {
    lib: 'custom',
    name: 'thOrder',
  },
  thOrderFilled: {
    lib: 'custom',
    name: 'thOrderFilled',
  },
  thQr: {
    lib: 'custom',
    name: 'thQr',
  },
  thTimsCard: {
    lib: 'custom',
    name: 'thTimsCard',
  },
  thUsFlag: {
    lib: 'custom',
    name: 'thUsFlag',
  },
  tiktok: {
    lib: 'custom',
    name: 'tiktok',
  },
  time: {
    lib: MaterialIcons,
    name: 'access-time',
  },
  timsCard: {
    lib: 'custom',
    name: 'timsCard',
  },
  twitter: {
    lib: 'custom',
    name: 'twitter',
  },
  unlocked: {
    lib: MaterialIcons,
    name: 'lock-open',
  },
  visa: {
    lib: 'custom',
    name: 'visa',
  },
  visibility: {
    lib: MaterialIcons,
    name: 'visibility',
  },
  visibilityOff: {
    lib: MaterialIcons,
    name: 'visibility-off',
  },
  voice: {
    lib: MaterialIcons,
    name: 'keyboard-voice',
  },
  warning: {
    lib: MaterialIcons,
    name: 'warning',
  },
  youtube: {
    lib: 'custom',
    name: 'youtube',
  },
};

export const getCustomIcon = (icon: string) => {
  // @ts-ignore
  const FoundIcon = SVGs[icon];
  if (!FoundIcon) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error(`getCustomIcon icon ${icon} not found`);
    }
  }
  return FoundIcon;
};
