import React from 'react';

import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

import { ISVGProps } from './types';

export const bkOffers = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.349967 10.3237C0.349967 10.408 0.401189 10.4866 0.453704 10.5673C0.479016 10.6061 0.504628 10.6455 0.524951 10.6861L0.599933 10.8111L0.81237 11.1235L0.899861 11.261L1.14983 11.5609L1.24975 11.6734C1.55898 12.0083 1.90246 12.3099 2.27456 12.5732L2.44954 12.6982L2.76198 12.8982L3.01195 13.0481L3.32438 13.2231L3.63682 13.3731L3.93675 13.5105L4.33668 13.673L4.59916 13.773L4.91159 13.873V13.1856C4.91598 10.9926 5.63063 8.85992 6.94862 7.10711C8.26661 5.35429 10.117 4.07562 12.2226 3.46254C11.0911 3.15026 9.92207 2.99468 8.74827 3.00014C3.93672 3.00014 0 5.56211 0 8.72399C0.00322813 9.27585 0.122487 9.82086 0.349967 10.3237ZM5.78636 17.2598C2.67448 16.5224 0.387489 14.6603 0.0625531 12.4233C1.42579 13.9007 3.2027 14.9329 5.16149 15.3852C5.31122 16.0281 5.52042 16.6556 5.78636 17.2598ZM0.0625531 15.6476C1.82425 17.4927 4.18597 18.6492 6.72368 18.9095C7.21315 19.6114 7.78831 20.2496 8.43583 20.8091C4.03671 20.7091 0.474971 18.4846 0.0625531 15.6476ZM10.6909 6.01243C12.1006 5.0727 13.7574 4.5724 15.4516 4.57488C17.7188 4.57488 19.8931 5.4755 21.4962 7.07861C23.0993 8.68173 24 10.856 24 13.1232C24.0025 14.8174 23.5022 16.4743 22.5624 17.884C21.6227 19.2937 20.2858 20.393 18.721 21.0424C17.1562 21.6919 15.4339 21.8625 13.7721 21.5326C12.1103 21.2027 10.5837 20.3871 9.38573 19.1891C8.18773 17.9911 7.37213 16.4646 7.04221 14.8028C6.71229 13.141 6.88288 11.4186 7.53238 9.85383C8.18188 8.28904 9.28113 6.95215 10.6909 6.01243ZM10.0152 11.5826C9.90272 11.0116 10.4355 10.5469 10.9169 10.7961L12.8884 11.8171C13.1887 11.9726 13.5475 11.8512 13.7159 11.537L14.8895 9.34744C15.1403 8.87946 15.7587 8.88543 16.002 9.35817L17.1402 11.5697C17.3002 11.8806 17.6467 12.011 17.9469 11.8734L20.1097 10.8815C20.5898 10.6614 21.0966 11.1265 20.9842 11.6842L20.0237 16.4475C19.9586 16.7703 19.6979 17 19.3968 17H11.601C11.2986 17 11.0373 16.7685 10.9733 16.4443L10.0152 11.5826Z"
    />
  </Icon>
);
