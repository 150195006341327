import React from 'react';

import { Header, Text, View } from '~/components';

import { IInputLabelProps } from './types';

export const InputLabel = ({ header, subHeader }: IInputLabelProps) => (
  <View ml={2}>
    <Header variant="headerThree" mb={1}>
      {header}
    </Header>
    <Text variant="copyOne">{subHeader}</Text>
  </View>
);
