import React from 'react';

import { SVGIconComponent } from '../types';

export const cart: SVGIconComponent = ({ title = '', ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <title>{title}</title>
    <path d="m18 6h-2c0-2.21-1.79-4-4-4s-4 1.79-4 4h-2c-1.1 0-2 0.9-2 2v12c0 1.1 0.9 2 2 2h12c1.1 0 2-0.9 2-2v-12c0-1.1-0.9-2-2-2zm-8 4c0 0.55-0.45 1-1 1s-1-0.45-1-1v-2h2v2zm2-6c1.1 0 2 0.9 2 2h-4c0-1.1 0.9-2 2-2zm4 6c0 0.55-0.45 1-1 1s-1-0.45-1-1v-2h2v2z" />
  </svg>
);
