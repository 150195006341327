import React from 'react';

import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

import { ISVGProps } from './types';

export const bkCrown = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Path
      d="m2.0277 9.278c-0.20458-0.94579 0.76414-1.7156 1.6393-1.3027l3.5847 1.6912c0.54601
      0.2576 1.1984 0.05645 1.5045-0.46392l2.1338-3.6271c0.456-0.77521 1.5805-0.76534 2.0228
      0.01777l2.0695 3.6634c0.2908 0.51498 0.9208 0.73106 1.4666
      0.50306l3.9324-1.643c0.8729-0.36473 1.7944 0.40584 1.59 1.3296l-1.7464 7.8906c-0.1182
      0.5347-0.5923 0.9152-1.1398 0.9152h-14.174c-0.54968
      0-1.0249-0.3834-1.1411-0.9206l-1.742-8.0535z"
    />
  </Icon>
);
