import { IIcon } from '../types/icon';

export const commonIcons: IIcon = {
  account: 'account',
  add: 'add',
  amex: 'amex',
  applepay: 'applepay',
  arrowUp: 'arrowUp',
  back: 'back',
  bkCrown: 'noop',
  calendar: 'calendar',
  cart: 'cart',
  catering: 'catering',
  cateringTwo: 'cateringTwo',
  check: 'check',
  checkCircle: 'checkCircle',
  circlePlus: 'circlePlus',
  close: 'close',
  cluckNorris: 'noop',
  cluckNorrisLoveThatChicken: 'noop',
  cluckNorrisSaxophone: 'noop',
  coffeeCupFilled: 'coffeeCupFilled',
  combo: 'noop',
  curbside: 'curbside',
  deleteCredit: 'deleteCredit',
  delivery: 'delivery',
  desserts: 'desserts',
  dineIn: 'dineIn',
  discover: 'discover',
  drinks: 'drinks',
  drive: 'drive',
  driveThru: 'driveThru',
  edit: 'edit',
  editWithEraser: 'editWithEraser',
  error: 'error',
  facebook: 'facebook',
  familyMeals: 'noop',
  fastFood: 'fastFood',
  favorite: 'favorite',
  favoriteFill: 'favoriteFill',
  gear: 'gear',
  googlePay: 'googlePay',
  home: 'home',
  halal: 'halal',
  hotDrink: 'hotDrink',
  info: 'info',
  instagram: 'instagram',
  kidsMeal: 'noop',
  kioskCard: 'kioskCard',
  language: 'language',
  list: 'list',
  location: 'location',
  lock: 'lock',
  lockUnlocked: 'lockUnlocked',
  logo: 'noop',
  loyaltyCard: 'noop',
  map: 'map',
  mapleLeaf: 'noop',
  mapleLeafFilled: 'noop',
  mastercard: 'mastercard',
  menu: 'menu',
  mobile: 'mobile',
  mobileNavHome: 'noop',
  new: 'WhatsNewIcon',
  next: 'next',
  offer: 'offer',
  order: 'noop',
  paypal: 'paypal',
  paymentCard: 'paymentCard',
  paymentCash: 'paymentCash',
  plkRewards: 'noop',
  poppyBag: 'poppyBag',
  previous: 'previous',
  profile: 'profile',
  qr: 'qr',
  mobileRaggedEdge: 'noop',
  desktopRaggedEdge: 'noop',
  refresh: 'refresh',
  remove: 'remove',
  restaurant: 'restaurant',
  rewards: 'noop',
  sadFace: 'sadFace',
  sandwiches: 'sandwiches',
  sauces: 'sauces',
  scanRewards: 'noop',
  seafood: 'noop',
  search: 'search',
  select: 'select',
  settings: 'settings',
  sides: 'noop',
  signatureChicken: 'noop',
  sodiumFlag: 'sodiumFlag',
  store: 'store',
  storeThree: 'storeThree',
  storeTwo: 'storeTwo',
  storeMarker: 'noop',
  swap: 'swap',
  telephone: 'telephone',
  tendersAndWings: 'noop',
  tiktok: 'tiktok',
  time: 'time',
  timsCard: 'noop',
  timsLogo: 'noop',
  timsText: 'noop',
  visa: 'visa',
  visibility: 'visibility',
  visibilityOff: 'visibilityOff',
  voice: 'voice',
  twitter: 'twitter',
  warning: 'warning',
  youtube: 'youtube',
};
