import React from 'react';

import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

import { ISVGProps } from './types';

export const back = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Path d="M17 20.23L15.1954 22L5 12L15.1954 2L17 3.77L8.60918 12L17 20.23Z" />
  </Icon>
);
