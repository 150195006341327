import React from 'react';

import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

import { ISVGProps } from './types';

export const plkStore = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.24325 4.09884C3.91575 4.09884 3.65018 3.83328 3.65018 3.5057V2.59315C3.65018 2.26557 3.91575 2 4.24325 2H19.7565C20.0843 2 20.3498 2.26557 20.3498 2.59315V3.5057C20.3498 3.83328 20.0843 4.09884 19.7565 4.09884H19.4373V6.56273H23.4067C23.7344 6.56273 24 6.82829 24 7.15588V20.9315C24 21.2591 23.7344 21.5247 23.4067 21.5247H0.593066C0.265565 21.5247 0 21.2591 0 20.9315V7.15588C0 6.82829 0.265565 6.56273 0.593066 6.56273H4.56273V4.09884H4.24325ZM18.2509 4.09884V20.3384H16.1063V11.7186H17.9314L16.1063 8.06842H15.1938L16.1063 11.7186H15.6501L14.7375 8.06842H13.6425L14.0988 11.7186H13.6425L13.1862 8.06842H12.228V11.7186H11.7718V8.06842H10.7224L10.2661 11.7186H9.80987L10.2661 8.06842H9.26225L8.34971 11.7186H7.89343L8.80598 8.06842H7.89343L6.06834 11.7186H7.89343V20.3384H5.74908V4.09884H18.2509ZM22.8136 8.66157H19.4373V9.89352H20.6691L22.4942 13.5437H19.4373V20.3384H22.8136V8.66157ZM1.18635 8.5247V20.3384H4.56273V13.5437H1.50561L3.3307 9.89352H4.56273V8.5247H1.18635Z"
    />
  </Icon>
);
