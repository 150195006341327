import React from 'react';

import { Icon } from 'native-base';
import { Path, Rect } from 'react-native-svg';

import { ISVGProps } from './types';

export const cash = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Rect y="4" width="24" height="16" rx="2" fill="#198737" />
    <Path
      d="M11.85 16.767H12.318C12.656 16.767 12.864 16.572 12.864 16.221V15.753C12.864 15.649 12.929 15.571 13.033 15.545C14.177 15.259 14.905 14.336 14.905 13.166C14.905 11.658 13.982 10.969 12.669 10.579C11.356 10.189 10.966 9.916 10.966 9.435C10.966 9.019 11.395 8.694 11.993 8.694C12.578 8.694 12.929 9.032 13.215 9.63C13.488 10.176 14.606 9.942 14.489 9.136C14.437 8.759 14.385 8.46 14.346 8.213C14.268 7.875 14.008 7.641 13.618 7.511C13.41 7.446 13.254 7.394 13.046 7.342C12.929 7.329 12.864 7.251 12.864 7.147V6.679C12.864 6.328 12.656 6.133 12.318 6.133H11.85C11.499 6.133 11.291 6.315 11.291 6.679V7.108C11.291 7.225 11.239 7.303 11.135 7.329C9.86104 7.641 9.10704 8.434 9.10704 9.604C9.10704 11.034 10.082 11.749 11.187 12.061C12.318 12.386 12.773 12.711 12.773 13.361C12.773 13.816 12.448 14.193 11.902 14.193C11.317 14.193 10.784 13.829 10.485 13.062C10.199 12.282 8.89904 12.607 9.06804 13.413C9.15904 13.842 9.21104 14.362 9.26304 14.648C9.31504 14.947 9.58804 15.129 9.92604 15.272C10.186 15.389 10.576 15.48 11.122 15.571C11.239 15.584 11.291 15.649 11.291 15.766V16.221C11.291 16.585 11.499 16.767 11.85 16.767Z"
      fill="#F5EBDC"
    />
  </Icon>
);
