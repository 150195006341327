import React from 'react';

import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

import { ISVGProps } from './types';

export const tiktok = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Path
      d="m15.483 2c0.33 2.8381 1.914 4.5302 4.6681 4.7102v3.1921c-1.596
      0.15604-2.9941-0.36602-4.6201-1.35v5.9702c0 7.5843-8.2683 9.9543-11.592
      4.5182-2.1361-3.4982-0.82802-9.6364 6.0242-9.8824v3.3661c-0.52202 0.084-1.08 0.216-1.59
      0.3901-1.524 0.516-2.3881 1.482-2.1481 3.1861 0.46201 3.2641 6.4502 4.2301
      5.9521-2.1481v-11.946h3.3062v-6e-3z"
      clipRule="evenodd"
      fillRule="evenodd"
    />
  </Icon>
);
