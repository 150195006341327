import React from 'react';

import { Icon } from 'native-base';
import { Path, Rect } from 'react-native-svg';

import { ISVGProps } from './types';

export const applepay = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Rect y="4" width="24" height="16" rx="2" fill="white" />
    <Rect
      x="0.5"
      y="4.5"
      width="23"
      height="15"
      rx="1.5"
      stroke="black"
      strokeOpacity="0.3"
      fill="white"
    />
    <Path
      d="M13.4408 8.60251C13.0918 9.02235 12.5334 9.35349 11.975 9.30619C11.9052 8.73851 12.1786 8.13535 12.4985 7.76282C12.8475 7.33114 13.4582 7.02365 13.9526 7C14.0108 7.59133 13.7839 8.17083 13.4408 8.60251ZM13.9468 9.41854C13.1383 9.37123 12.4462 9.88569 12.0623 9.88569C11.6726 9.88569 11.0851 9.44219 10.4453 9.45402C9.61356 9.46585 8.83997 9.94482 8.41537 10.7076C7.54291 12.2333 8.18853 14.4922 9.03191 15.7339C9.44488 16.3489 9.93928 17.023 10.5907 16.9994C11.2073 16.9757 11.4515 16.5914 12.1961 16.5914C12.9464 16.5914 13.1616 16.9994 13.813 16.9876C14.4877 16.9757 14.9123 16.3726 15.3253 15.7576C15.7964 15.0598 15.9884 14.3798 16 14.3443C15.9884 14.3325 14.6971 13.8299 14.6855 12.3161C14.6739 11.0506 15.7034 10.4475 15.7499 10.412C15.1682 9.53681 14.2609 9.44219 13.9468 9.41854Z"
      fill="black"
    />
  </Icon>
);
