import React from 'react';

import { Icon } from 'native-base';
import { Path, Rect } from 'react-native-svg';

import { ISVGProps } from './types';

export const googlePay = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Rect y="4" width="24" height="16" rx="2" fill="white" />
    <Rect
      x="0.5"
      y="4.5"
      width="23"
      height="15"
      rx="1.5"
      stroke="black"
      strokeOpacity="0.3"
      fill="white"
    />
    <Path
      d="M16.8007 12.1142C16.8011 11.7734 16.7723 11.4333 16.7146 11.0975H11.9995V13.0232H14.7001C14.6448 13.3307 14.5279 13.6239 14.3563 13.885C14.1846 14.1461 13.9619 14.3698 13.7015 14.5425V15.7925H15.3133C16.257 14.9224 16.8007 13.6356 16.8007 12.1142Z"
      fill="#4285F4"
    />
    <Path
      d="M11.9994 17.0001C13.3487 17.0001 14.4847 16.557 15.3132 15.7932L13.7014 14.5432C13.2528 14.8474 12.6751 15.021 11.9994 15.021C10.6953 15.021 9.5884 14.1418 9.19258 12.9572H7.53223V14.2453C7.94837 15.0734 8.58648 15.7695 9.37531 16.2559C10.1641 16.7424 11.0727 17 11.9994 17.0001Z"
      fill="#34A853"
    />
    <Path
      d="M9.19275 12.9571C8.98349 12.3363 8.98349 11.6641 9.19275 11.0433V9.75513H7.53239C7.18232 10.4517 7 11.2206 7 12.0002C7 12.7798 7.18232 13.5486 7.53239 14.2452L9.19275 12.9571Z"
      fill="#FBBC04"
    />
    <Path
      d="M11.9994 8.97942C12.7124 8.96777 13.4014 9.23717 13.9174 9.72939L15.3444 8.30236C14.4395 7.45245 13.2408 6.98583 11.9994 7.00033C11.0727 7.00037 10.1641 7.258 9.37531 7.74444C8.58648 8.23089 7.94837 8.92701 7.53223 9.75509L9.19258 11.0432C9.5884 9.85855 10.6953 8.97942 11.9994 8.97942Z"
      fill="#EA4335"
    />
  </Icon>
);
