import React, { FC } from 'react';

import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import { BackArrowHistory } from 'components/navigation/back-arrow';
import AuthRequired from 'pages/authentication/auth-required';

import { IPageContentProps, PageContent } from './page-content';
import { useAccountDelete } from './use-account-delete';
import { useFeatureAccountDeletePage } from './use-feature-account-delete-page';

export const AccountDelete: FC = () => {
  const { formatMessage } = useIntl();
  const {
    featureAccountDeletePageLoading,
    featureAccountDeletePage,
  } = useFeatureAccountDeletePage();
  const { handleAccountDelete, handleCancel, isLoading, isRejected } = useAccountDelete();

  const pageContentProps: IPageContentProps = {
    headerText: featureAccountDeletePage?.headerText?.locale,
    content: featureAccountDeletePage?.content?.locale,
    accountDeleteButtonText: featureAccountDeletePage?.confirmButtonText?.locale,
    cancelButtonText: featureAccountDeletePage?.cancelButtonText?.locale,
    onAccountDelete: handleAccountDelete,
    onCancel: handleCancel,
    isLoading,
    isRejected,
    errorText: featureAccountDeletePage?.errorText?.locale,
  };

  return (
    <>
      <Helmet title={formatMessage({ id: 'deleteAccount' })} />
      <AuthRequired>
        <BackArrowHistory />
        {!featureAccountDeletePageLoading && <PageContent {...pageContentProps} />}
      </AuthRequired>
    </>
  );
};
