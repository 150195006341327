import React from 'react';

import { Icon } from 'native-base';
import { G, Path, Rect } from 'react-native-svg';

import { ISVGProps } from './types';

export const plkMenu = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 16 24">
    <G transform="translate(-16 -8)" fillRule="evenodd">
      <Rect x={16} y={13} width={16} height={19} rx={0.5} />
      <Path
        d="M29 11.5V8.694a.5.5 0 0 0-.658-.475l-8.419 2.807a.5.5 0 0 0 .158.974H28.5a.5.5 0 0 0
        .5-.5z"
      />
    </G>
  </Icon>
);
