import React from 'react';

import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

import { ISVGProps } from './types';

export const plkAccount = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7276 9.41814C16.2516 8.63392 16.5313 7.71192 16.5313 6.76875C16.5313 5.504 16.0288 4.29107 15.1345 3.39675C14.2402 2.50244 13.0272 2 11.7625 2C10.8193 2 9.89733 2.27968 9.11311 2.80368C8.3289 3.32768 7.71769 4.07246 7.35675 4.94384C6.99582 5.81522 6.90137 6.77405 7.08537 7.6991C7.26938 8.62415 7.72354 9.47385 8.39046 10.1408C9.05738 10.8077 9.90708 11.2619 10.8321 11.4459C11.7572 11.6299 12.716 11.5354 13.5874 11.1745C14.4588 10.8136 15.2036 10.2024 15.7276 9.41814ZM4.92892 15.5852C3.05356 17.4606 2 20.0041 2 22.6563H22C22 20.0041 20.9464 17.4606 19.071 15.5852C17.1957 13.7098 14.6522 12.6563 12 12.6563C9.34784 12.6563 6.80429 13.7098 4.92892 15.5852Z"
    />
  </Icon>
);
