import React from 'react';

import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

import { ISVGProps } from './types';

export const search = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Path
      d="m15.5 14h-0.79l-0.28-0.27c0.98-1.14 1.57-2.62 1.57-4.23 0-3.59-2.91-6.5-6.5-6.5s-6.5
      2.91-6.5 6.5 2.91 6.5 6.5 6.5c1.61 0 3.09-0.59 4.23-1.57l0.27
      0.28v0.79l5 4.99 1.49-1.49-4.99-5zm-6 0c-2.49 0-4.5-2.01-4.5-4.5s2.01-4.5 4.5-4.5 4.5 2.01
      4.5 4.5-2.01 4.5-4.5 4.5z"
    />
  </Icon>
);
