import * as React from 'react';

import styled from 'styled-components';

import { ISanityBlockContent } from '@rbi-ctg/menu';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import AccountLayout from 'components/layout/account';
import { brandFont } from 'components/layout/brand-font';
import SanityBlockRenderer from 'components/sanity-block-renderer';
import useMediaQuery from 'hooks/use-media-query';
import { primitive } from 'styles/constants/primitives';

const ButtonContainer = styled.div`
  margin: ${primitive.$spacing7} auto 0;
  width: 90%;

  ${Styles.desktop`
    width: 80%;
  `}

  & > :last-child {
    margin-top: ${primitive.$spacing7};
  }
`;

const Error = styled.p<{ visibility: string }>`
  font: ${brandFont.copyOne};
  color: ${primitive.$error};
  text-align: center;

  visibility: ${p => p.visibility};
`;

export interface IPageContentProps {
  headerText?: string | null;
  content?: ISanityBlockContent[] | null;
  accountDeleteButtonText?: string | null;
  cancelButtonText?: string | null;
  onAccountDelete(): void;
  onCancel(): void;
  isLoading: boolean;
  isRejected: boolean;
  errorText?: string | null;
}

export const PageContent: React.FC<IPageContentProps> = ({
  headerText,
  content,
  accountDeleteButtonText,
  cancelButtonText,
  onAccountDelete,
  onCancel,
  isLoading,
  isRejected,
  errorText,
}) => {
  const isMobile = useMediaQuery('mobile');
  return (
    <AccountLayout header={headerText}>
      <SanityBlockRenderer content={content} />
      <ButtonContainer>
        <ActionButton
          onlyIcon={isMobile}
          fullWidth
          variant={ActionButtonVariants.OUTLINE}
          onClick={onCancel}
        >
          {cancelButtonText}
        </ActionButton>
        <ActionButton
          onlyIcon={isMobile}
          variant={ActionButtonVariants.OUTLINE}
          onClick={onAccountDelete}
          isLoading={isLoading}
          loadingColor={primitive.$error}
          fullWidth
          data-testid="delete-my-account-link"
        >
          {accountDeleteButtonText}
        </ActionButton>
      </ButtonContainer>
      <Error visibility={isRejected ? 'visible' : 'hidden'}>{errorText}</Error>
    </AccountLayout>
  );
};
