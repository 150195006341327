import React from 'react';

import { Icon } from 'native-base';
import { Path, Rect } from 'react-native-svg';

import { ISVGProps } from './types';

export const addCard = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Rect y="4" width="24" height="16" rx="2" fill="white" />
    <Rect
      x="0.5"
      y="4.5"
      width="23"
      height="15"
      rx="1.5"
      stroke="black"
      strokeOpacity="0.3"
      fill="white"
    />
    <Path
      d="M15.5 12.5H12.5V15.5H11.5V12.5H8.5V11.5H11.5V8.5H12.5V11.5H15.5V12.5Z"
      fill="#1F1F1F"
    />
  </Icon>
);
