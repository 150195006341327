import React from 'react';

import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

import { ISVGProps } from './types';

export const youtube = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Path
      d="m24 16.677v-8.554s0-4.123-4.1359-4.123h-15.73s-4.1345 1e-5 -4.1345 4.123v8.554s-3.991e-6
      4.123 4.1345 4.123h15.73s4.1359 0 4.1359-4.123zm-7.3405-4.265-7.8571 4.5998v-9.2011l7.8571
      4.6013z"
      fillRule="evenodd"
    />
  </Icon>
);
