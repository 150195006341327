import React from 'react';

import { Icon } from 'native-base';
import { Path, Rect } from 'react-native-svg';

import { ISVGProps } from './types';

export const paymentMethods = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 17">
    <Rect
      fill={props.color}
      x="0.512695"
      y="3.16873"
      width="22.3885"
      height="9.66667"
      rx="1.5"
      stroke="#1F1F1F"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.0126953 4.66873H23.4012V7.3354H0.0126953V4.66873Z"
      fill={props.color}
    />
  </Icon>
);
