import React from 'react';

import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

import { ISVGProps } from './types';

export const bkSaving = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Path d="M17.83 5.5L15.56 3.23C15.63 2.81 15.74 2.42 15.88 2.08C15.96 1.9 16 1.71 16 1.5C16 0.67 15.33 0 14.5 0C12.86 0 11.41 0.79 10.5 2H5.5C2.46 2 0 4.46 0 7.5C0 10.54 2.5 19 2.5 19H8V17H10V19H15.5L17.18 13.41L20 12.47V5.5H17.83ZM11 7H6V5H11V7ZM14 9C13.45 9 13 8.55 13 8C13 7.45 13.45 7 14 7C14.55 7 15 7.45 15 8C15 8.55 14.55 9 14 9Z" />
  </Icon>
);
