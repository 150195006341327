import React from 'react';

import { Icon } from 'native-base';
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';

import { ISVGProps } from './types';

export const plkPickupFilled = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <G clipPath="url(#clip0_1357_12)">
      <Path d="M12.16 2.45C12.45 2.54 12.85 2.73 13.7 2.38C13.7 3.23 13.7 5.19 13.69 6.17C13.69 7.23 13.76 8.83 13.48 9.99C14.23 10.08 14.74 10.87 15.54 10.56C15.82 10.45 16.39 9.96 16.68 9.9C17.36 9.76 18.06 10.43 18.7 10.15C19.43 9.83 19.76 9.53 19.53 8.77C18.57 6.67 17.4 4.52 17.4 4.52L17.42 4.51C18.2 4.31 18.35 3.3 17.44 2.76L18.09 2.16L17.14 1.77L17.49 0.470002L15.45 1.28H15.46C15.46 1.28 14.94 0.200002 14.16 0.190002C13.5 0.180002 13.18 0.660002 13.18 0.660002C13.18 0.660002 13 0.170002 12.55 0.0300024C12.1 -0.0999976 11.36 0.160002 11.27 0.990002C11.16 1.9 11.91 2.37 12.16 2.45ZM15.64 2.06C15.85 1.95 16.12 2.06 16.23 2.29C16.35 2.52 16.28 2.8 16.07 2.91C15.86 3.02 15.59 2.91 15.48 2.68C15.36 2.44 15.43 2.16 15.64 2.06Z" />
      <Path d="M7.01 8.96C7.44 9.3 7.61 9.77 7.64 10.29C7.65 10.42 7.72 10.53 7.84 10.59C8.19 10.77 8.55 10.89 8.77 10.9C9.31 10.92 9.72 10.33 10.21 10.4C10.23 10.15 10.26 9.87 10.32 9.57C10.64 7.96 10.91 6.36 9.56 5.17C8.23 3.97 5.8 3.84 3 7.22L4.83 9.43C4.83 9.43 5.81 7.99 7.01 8.96Z" />
      <Path d="M20.6601 11.41C20.6601 11.03 20.6101 10.41 20.3401 10.1C19.9701 9.67 19.5101 10.16 19.3301 10.53C19.1801 10.83 19.0001 11.18 18.6401 11.23C18.1601 11.29 17.8601 10.75 17.4001 10.59C16.9201 10.41 16.4001 10.69 15.9701 10.94C15.5401 11.19 14.9901 11.44 14.5401 11.22C14.3001 11.1 14.1501 10.88 13.9701 10.69C13.7901 10.5 13.5401 10.32 13.2701 10.36C12.7201 10.44 12.4901 11.25 11.9701 11.48C11.7401 11.58 11.4801 11.62 11.2301 11.53C11.0001 11.45 10.8101 11.29 10.6101 11.17C10.4001 11.05 10.1401 10.96 9.91007 11.05C9.85007 11.07 9.79007 11.11 9.74007 11.14C9.56007 11.25 9.38007 11.42 9.18007 11.5C9.01007 11.57 8.80007 11.58 8.61007 11.55C8.23007 11.49 7.88007 11.27 7.63007 11C6.75007 10.08 6.55007 10.61 6.49007 11.12C6.46007 11.35 6.16007 12.27 6.26007 13.43C6.32007 14.17 6.44007 15.22 6.53007 16.05C6.78007 18.33 6.76007 20.57 6.98007 22.85C7.10007 24.16 7.83007 23.99 9.10007 23.97C9.49007 23.96 10.0801 23.86 11.3201 23.88C11.8801 23.89 13.1101 23.78 13.7501 23.79C14.6801 23.8 15.8601 23.87 16.7901 23.88C18.1801 23.89 18.5901 23.94 19.4501 23.94C19.9701 23.94 20.3401 23.57 20.3301 23.04C20.3101 22.39 20.3501 21.74 20.4001 21.08C20.4401 20.62 20.4801 20.17 20.5301 19.72C20.6601 18.41 20.5901 17.07 20.5701 15.75C20.5601 15.27 20.6301 14.8 20.6301 14.34C20.6401 13.19 20.6501 12.15 20.6601 11.41ZM16.6501 16.22C14.1801 16.26 11.1901 16.3 9.48007 16.3C7.76007 16.31 7.26007 15.89 9.65007 15.75C10.7501 15.69 11.8501 15.63 12.9501 15.58C13.4801 15.56 14.0101 15.54 14.5301 15.52C14.7701 15.51 15.0301 15.47 15.2701 15.52C15.5301 15.57 15.7701 15.6 16.0301 15.6C16.2701 15.6 16.5101 15.58 16.7401 15.56C17.0601 15.53 17.3701 15.5 17.6901 15.48C17.9301 15.46 18.1701 15.46 18.4101 15.51C18.4701 15.52 18.5401 15.54 18.5901 15.58C18.7301 15.68 18.7301 15.86 18.7101 16.02C18.0201 16.11 17.3401 16.21 16.6501 16.22Z" />
    </G>
    <Defs>
      <ClipPath id="clip0_1357_12">
        <Rect width="17.66" height="24" fill="white" transform="translate(3)" />
      </ClipPath>
    </Defs>
  </Icon>
);
