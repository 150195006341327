import React from 'react';

import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

import { ISVGProps } from './types';

export const filters = (props: ISVGProps) => (
  <Icon {...props} data-legacy-icon viewBox="0 0 24 24">
    <Path d="M8.25 3.44444C7.55964 3.44444 7 3.99165 7 4.66667C7 5.34168 7.55964 5.88889 8.25 5.88889C8.94036 5.88889 9.5 5.34168 9.5 4.66667C9.5 3.99165 8.94036 3.44444 8.25 3.44444ZM4.71338 3.44444C5.22818 2.02032 6.61723 1 8.25 1C9.88277 1 11.2718 2.02032 11.7866 3.44444H20.75C21.4404 3.44444 22 3.99165 22 4.66667C22 5.34168 21.4404 5.88889 20.75 5.88889H11.7866C11.2718 7.31301 9.88277 8.33333 8.25 8.33333C6.61723 8.33333 5.22818 7.31301 4.71338 5.88889H3.25C2.55964 5.88889 2 5.34168 2 4.66667C2 3.99165 2.55964 3.44444 3.25 3.44444H4.71338ZM15.75 10.7778C15.0596 10.7778 14.5 11.325 14.5 12C14.5 12.675 15.0596 13.2222 15.75 13.2222C16.4404 13.2222 17 12.675 17 12C17 11.325 16.4404 10.7778 15.75 10.7778ZM12.2134 10.7778C12.7282 9.35365 14.1172 8.33333 15.75 8.33333C17.3828 8.33333 18.7718 9.35365 19.2866 10.7778H20.75C21.4404 10.7778 22 11.325 22 12C22 12.675 21.4404 13.2222 20.75 13.2222H19.2866C18.7718 14.6463 17.3828 15.6667 15.75 15.6667C14.1172 15.6667 12.7282 14.6463 12.2134 13.2222H3.25C2.55964 13.2222 2 12.675 2 12C2 11.325 2.55964 10.7778 3.25 10.7778H12.2134ZM8.25 18.1111C7.55964 18.1111 7 18.6583 7 19.3333C7 20.0083 7.55964 20.5556 8.25 20.5556C8.94036 20.5556 9.5 20.0083 9.5 19.3333C9.5 18.6583 8.94036 18.1111 8.25 18.1111ZM4.71338 18.1111C5.22818 16.687 6.61723 15.6667 8.25 15.6667C9.88277 15.6667 11.2718 16.687 11.7866 18.1111H20.75C21.4404 18.1111 22 18.6583 22 19.3333C22 20.0083 21.4404 20.5556 20.75 20.5556H11.7866C11.2718 21.9797 9.88277 23 8.25 23C6.61723 23 5.22818 21.9797 4.71338 20.5556H3.25C2.55964 20.5556 2 20.0083 2 19.3333C2 18.6583 2.55964 18.1111 3.25 18.1111H4.71338Z" />
  </Icon>
);
