import React from 'react';

import LoadingAnimation from 'components/loading-animation';
import LoadingContainer from 'components/loading-animation/loading-container';

/**
 * This page just shows a loading state indefinitely.
 */
const LoadingPage = () => {
  return (
    <div data-testid="loading-page">
      <LoadingContainer>
        <LoadingAnimation fillColor={Styles.color.primary} />
      </LoadingContainer>
    </div>
  );
};

export default LoadingPage;
