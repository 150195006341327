import React from 'react';

import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

import { ISVGProps } from './types';

export const bkDaytime = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.541 6C13.141 6 13.541 5.6 13.541 5V1C13.541 0.4 13.141 0 12.541 0C11.941 0 11.541 0.4 11.541 1V5C11.541 5.6 11.941 6 12.541 6ZM12.541 7C9.74095 7 7.54095 9.2 7.54095 12C7.54095 14.8 9.74095 17 12.541 17C15.341 17 17.541 14.8 17.541 12C17.541 9.2 15.341 7 12.541 7ZM12.541 15C10.841 15 9.54095 13.7 9.54095 12C9.54095 10.3 10.841 9 12.541 9C14.241 9 15.541 10.3 15.541 12C15.541 13.7 14.241 15 12.541 15ZM11.541 19C11.541 18.4 11.941 18 12.541 18C13.141 18 13.541 18.4 13.541 19V23C13.541 23.6 13.141 24 12.541 24C11.941 24 11.541 23.6 11.541 23V19ZM22.541 16.6L19.041 14.6C18.541 14.3 17.941 14.5 17.641 15C17.341 15.5 17.541 16.1 18.041 16.4L21.541 18.4C22.041 18.7 22.641 18.5 22.941 18C23.141 17.5 23.041 16.9 22.541 16.6ZM3.54095 5.59999L7.04095 7.59999C7.44095 7.89999 7.64095 8.49999 7.34095 8.99999C7.14095 9.49999 6.44095 9.59999 6.04095 9.39999L2.54095 7.39999C2.04095 7.09999 1.84095 6.49999 2.14095 5.99999C2.44095 5.49999 3.04095 5.39999 3.54095 5.59999ZM6.04095 14.6L2.54095 16.6C2.04095 16.9 1.94095 17.5 2.14095 18C2.34095 18.5 3.04095 18.6 3.54095 18.4L7.04095 16.4C7.54095 16.1 7.64095 15.5 7.44095 15C7.14095 14.5 6.44095 14.4 6.04095 14.6ZM22.5409 7.39999L19.0409 9.39999C18.5409 9.59999 17.9409 9.49999 17.6409 8.99999C17.4409 8.49999 17.5409 7.89999 18.0409 7.59999L21.5409 5.59999C22.0409 5.39999 22.6409 5.49999 22.9409 5.99999C23.1409 6.49999 23.0409 7.09999 22.5409 7.39999Z"
    />
  </Icon>
);
