import React, { ReactNode, createContext } from 'react';

import { NativeBaseProvider } from 'native-base';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components/native';

import { UclTheme } from './theme-types';

export interface IUclContext {
  /**
   * Universal Component Library theme.
   *
   * Use this to access the theme tokens.
   */
  theme: UclTheme;
}

/**
 * Context for the universal component library
 */
export const uclContext = createContext<IUclContext>({
  theme: {} as UclTheme,
});

interface IInitialWindowMetrics {
  frame?: {
    height: number;
    width: number;
    x: number;
    y: number;
  };
  insets?: {
    bottom: number;
    left: number;
    right: number;
    top: number;
  };
}

export interface IUclProviderProps {
  children?: ReactNode;
  initialWindowMetrics?: IInitialWindowMetrics;
  theme: UclTheme;
}

/**
 * Provides Universal Component Library context.
 *
 * This includes providers for native styled-components and NativeBase.
 * Use this theme to inject those themes into scope.
 *
 * If using for a specific theme, or the theme, also declare the UclCustomTheme override.
 * this will create types for Styled-components theme and NativeBase props.
 *
 * ```typescript
 *
 * declare module '@rbilabs/universal-components'{
 *  export interface UclCustomTheme extends BkTheme{}
 * }
 * ````
 */
export const UclProvider = ({ children, theme, initialWindowMetrics }: IUclProviderProps) => (
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  <uclContext.Provider value={{ theme }}>
    <StyledComponentsThemeProvider theme={theme}>
      <NativeBaseProvider
        theme={theme.nativeBaseTheme}
        initialWindowMetrics={initialWindowMetrics}
        // TODO remove when NB fixes accessibility warning bug
        config={{
          suppressColorAccessibilityWarning: true,
        }}
      >
        {children}
      </NativeBaseProvider>
    </StyledComponentsThemeProvider>
  </uclContext.Provider>
);
