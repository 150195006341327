import React from 'react';

import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

import { ISVGProps } from './types';

export const facebook = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Path
      d="m13.36 22h-4.2412v-10.002h-2.1193v-3.446h2.1193v-2.0689c0-2.8111 1.196-4.4837
      4.5962-4.4837h2.8301v3.4473h-1.7688c-1.3237 0-1.4112 0.48156-1.4112 1.3803l-0.0058
      1.7249h3.2052l-0.3752 3.446h-2.83v10.001h7e-4v1e-4z"
      fillRule="evenodd"
    />
  </Icon>
);
