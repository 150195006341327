/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable filename-rules/match */
import React, { ComponentProps, ComponentType, forwardRef } from 'react';

import { mergeWith } from 'lodash';

/**
 * prevents `mergeWith` from merging arrays.
 */
const mergeCopyArrays = (objValue: any, srcValue: any) =>
  Array.isArray(objValue) ? srcValue : undefined;

const mergeProps = (
  props: Record<string, any>,
  appliedProps: Record<string, any> | ((input: Record<string, any>) => any),
) =>
  typeof appliedProps === 'function'
    ? { ...mergeWith({}, props, appliedProps(props), mergeCopyArrays) }
    : { ...mergeWith({}, appliedProps, props, mergeCopyArrays) };

/**
 * Applies props to a component as a new component. Similar to
 * `Styled(Component)` but only with props.
 *
 * Features:
 * - Deep merges props with overwrite order of `JSX > outer > inner`
 * - Overwrites arrays instead of merging.
 * - Overwrites function props instead of composing them.\
 *   *(Note: this can be changed on feature request.)*
 * - Passing a function as prop to use and modify props in order of
 *   `Original props > outer > inner`.
 * - Passes `refs` automatically if source component is forwardRef.
 * ## Example
 * ```tsx
 * const NewComponent = withConfig(SampleComponent,{someProp:true});
 * ```
 */
export const withConfig = <
  C extends ComponentType<any> | React.ForwardRefExoticComponent<any & React.RefAttributes<any>>,
>(
  component: C,
  appliedProps:
    | Partial<ComponentProps<C>>
    | ((props: ComponentProps<C>) => Partial<ComponentProps<C>>),
) => {
  const Component = component as any;

  if (Component.$$typeof === Symbol.for('react.forward_ref')) {
    return forwardRef((props: ComponentProps<C>, ref: any) => (
      <Component {...mergeProps(props, appliedProps)} ref={ref} />
    )) as unknown as C;
  }

  return ((props: ComponentProps<C>) => (
    <Component {...mergeProps(props, appliedProps)} />
  )) as unknown as C;
};

type ComponentWithConfig<C extends ComponentType<any>> = C & {
  withConfig: (props: ComponentProps<C>) => ComponentWithConfig<C>;
};

/**
 * Adds a `.withConfig()` method that applies props to a component as a new component.
 *
 * Features:
 * - Deep merges props with overwrite order of `JSX > outer > inner`
 * - Overwrites arrays instead of merging.
 * - Overwrites function props instead of composing them.\
 *   *(Note: this can be changed on feature request.)*
 * - Passing a function as prop to use and modify props in order of
 *   `Original props > outer > inner`.
 * - Passes `refs` automatically if source component is forwardRef.
 *
 * ## Example
 * ```tsx
 * const NewComponent = addWithConfig(SampleComponent);
 *
 * const StyledComponent = NewComponent.withConfig({someProp:true});
 * ```
 */
export const addWithConfig = <C extends ComponentType<any>>(component: C) => {
  const newcomponent = component as ComponentWithConfig<C>;

  newcomponent.withConfig = (props: ComponentProps<C>) =>
    addWithConfig(withConfig(newcomponent, props));

  return newcomponent;
};

/**
 * Adds `Component.withConfig` to every component in an object of components.
 */
export const addWithConfigToMap = <ComponentList extends Record<string, ComponentType<any>>>(
  components: ComponentList,
) =>
  Object.keys(components).reduce(
    (componentsList, key) => ({
      ...componentsList,
      [key]: addWithConfig(components[key]),
    }),
    {} as {
      [ComponentName in keyof ComponentList]: ComponentWithConfig<ComponentList[ComponentName]>;
    },
  );
