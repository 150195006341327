import React from 'react';

import { useIntl } from 'react-intl';
import { Navigate, useMatch } from 'react-router-dom';

import { routes } from 'utils/routing';

const OffersPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { params } = useMatch(`${routes.offers}/:offerId`) || {};
  const offerId = params?.offerId;

  return (
    <Navigate
      to={`${formatMessage({ id: 'routes.loyaltyOfferList' })}${offerId ? `/${offerId}` : ''}`}
      replace
    />
  );
};

export default OffersPage;
