import React from 'react';

import { Icon } from 'native-base';
import { Path, Rect } from 'react-native-svg';

import { ISVGProps } from './types';

export const diners = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Rect y="4" width="24" height="16" rx="2" fill="white" />
    <Rect
      x="0.5"
      y="4.5"
      width="23"
      height="15"
      rx="1.5"
      stroke="black"
      strokeOpacity="0.3"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9724 16H12.4258C14.6945 16 16.5023 14.2262 16.5732 12.035V12.0002C16.5378 9.7743 14.6945 7.96571 12.4258 8.00049H10.7951C8.63277 8.07005 6.93124 9.87864 7.00214 12.035C7.00214 14.1914 8.77456 15.9652 10.9724 16ZM7.71094 12.0002C7.71094 10.1916 9.23522 8.69608 11.0785 8.69608C12.9219 8.69608 14.4461 10.1916 14.4461 12.0002C14.4461 13.8088 12.9219 15.3044 11.0785 15.3044C9.23522 15.3044 7.71094 13.8088 7.71094 12.0002ZM11.6104 14.2262V9.80906C12.851 10.1221 13.6309 11.3394 13.3119 12.5567C13.0992 13.3914 12.4611 14.0175 11.6104 14.2262ZM8.88095 11.4437C8.56192 12.6611 9.30633 13.9132 10.547 14.2262V9.80906C9.73171 10.0177 9.09364 10.6438 8.88095 11.4437Z"
      fill="#145095"
    />
  </Icon>
);
