import {
  IFeatureAccountDeletePageQuery,
  useFeatureAccountDeletePageQuery,
} from 'generated/sanity-graphql';
import { useFeaturesContext } from 'state/features';

interface IUseFeatureAccountDeletePage {
  featureAccountDeletePageLoading: boolean;
  featureAccountDeletePage: IFeatureAccountDeletePageQuery['FeatureAccountDeletePage'];
}

export const useFeatureAccountDeletePage = (): IUseFeatureAccountDeletePage => {
  const { featureAccountDeletePageId = '', featureIdsLoading } = useFeaturesContext();

  const { data, loading } = useFeatureAccountDeletePageQuery({
    variables: { featureAccountDeletePageId },
    skip: !featureAccountDeletePageId,
  });

  const featureAccountDeletePage = data?.FeatureAccountDeletePage ?? null;
  const featureAccountDeletePageLoading = featureIdsLoading || loading;

  return { featureAccountDeletePageLoading, featureAccountDeletePage };
};
