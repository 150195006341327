import React from 'react';

import { Icon as NativeBaseIcon } from 'native-base';

import { getCustomIcon, iconMap } from './icon-map';
import { BaseIconProps } from './types';

// eslint-disable-next-line complexity
export const Icon = ({
  color,
  size,
  testID,
  accessible,
  accessibilityLabel,
  accessibilityHint,
  icon,
}: BaseIconProps) => {
  const foundIcon = iconMap[icon];
  if (!foundIcon) {
    if (process.env.NODE_ENV === 'development') {
      throw new Error(`getCustomIcon icon [$icon] not found`);
    }
  }

  const label = accessible ? accessibilityLabel : '';
  const hintLabel = accessible ? accessibilityHint : '';

  if (foundIcon.lib === 'custom') {
    const CustomIcon = getCustomIcon(foundIcon.name);
    return (
      // eslint-disable-next-line react-native-a11y/has-accessibility-hint
      <CustomIcon
        accessibilityLabel={label}
        accessible={accessible}
        testID={testID}
        size={size}
        title={foundIcon.name}
        color={color}
      />
    );
  }
  return (
    <NativeBaseIcon
      accessibilityHint={hintLabel}
      accessibilityLabel={label}
      accessible={accessible}
      testID={testID}
      as={foundIcon?.lib}
      size={size}
      name={foundIcon.name}
      color={color}
    />
  );
};
