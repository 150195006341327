import React from 'react';

import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

import { ISVGProps } from './types';

export const thOrder = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 4.0019H18.81L18 2.2819C17.9065 2.0949 17.7083 1.98392 17.5 2.0019H7.5C7.30947 2.00384 7.13592 2.11183 7.05 2.2819L6.19 4.0019H6C5.44772 4.0019 5 4.44961 5 5.0019V6.0019C5 6.55418 5.44772 7.0019 6 7.0019V7.1319L7.92 22.1319C7.98567 22.6329 8.41474 23.0062 8.92 23.0019H16.06C16.5653 23.0062 16.9943 22.6329 17.06 22.1319L19 7.1319V7.0019C19.5523 7.0019 20 6.55418 20 6.0019V5.0019C20 4.44961 19.5523 4.0019 19 4.0019ZM7.81 3.0019H17.19L17.69 4.0019H7.31L7.81 3.0019ZM16.07 22.0019H8.93L7 7.0019H18L16.07 22.0019ZM6 5.0019H19V6.0019H6V5.0019Z"
    />
  </Icon>
);
