import React from 'react';

import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

import { ISVGProps } from './types';

export const thHome = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C12.7887 4.26188 13.9746 5.02861 15.5577 4.3002C15.46 6.76267 15.3431 8.85127 15.2068 10.566L16.9919 8.3109C17.5189 10.1724 18.8868 10.5567 21.0957 9.46369C20.5169 11.0344 19.7754 12.5557 22 13.7784C19.3064 15.9156 17.4284 17.4176 16.3662 18.2842L17.9433 19.9415H15.0475V21.1759C13.6417 20.9525 12.6258 21.2272 12 22C11.3742 21.2272 10.3583 20.9525 8.95249 21.1759V19.9415H6.05674L7.63383 18.2842C6.57159 17.4176 4.69365 15.9156 2 13.7784C4.22462 12.5557 3.48305 11.0344 2.9043 9.46369C5.1132 10.5567 6.48113 10.1724 7.00809 8.3109L8.79316 10.566C8.65693 8.85127 8.53997 6.76267 8.44227 4.3002C10.0254 5.02861 11.2113 4.26188 12 2Z"
    />
  </Icon>
);
