import React from 'react';

import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

import { ISVGProps } from './types';

export const bkCode = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Path d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM7.64 14.47C7.64 14.76 7.4 15 7.11 15H7.02C6.73 15 6.49 14.76 6.49 14.47V10.5L6.04 10.83C5.8 11.01 5.45 10.95 5.28 10.69C5.13 10.45 5.18 10.14 5.41 9.97L6.6 9.12C6.71 9.04 6.84 9 6.98 9C7.34 9 7.64 9.29 7.64 9.66V14.47V14.47ZM13.01 15H10.34C9.94 15 9.62 14.68 9.62 14.28C9.62 14.09 9.7 13.9 9.83 13.77C10.78 12.82 11.41 12.19 11.75 11.83C12.15 11.41 12.29 11.14 12.29 10.77C12.29 10.37 11.98 10.05 11.48 10.05C11.14 10.05 10.91 10.21 10.76 10.42C10.61 10.62 10.35 10.68 10.12 10.58C9.78 10.44 9.67 10.01 9.9 9.73C10.05 9.54 10.27 9.35 10.57 9.2C11.26 8.87 12.05 9 12.52 9.23C13.38 9.67 13.43 10.47 13.43 10.71C13.43 11.35 13.12 11.97 12.51 12.57C12.26 12.82 11.79 13.28 11.11 13.96L11.14 14.01H13.02C13.29 14.01 13.51 14.23 13.51 14.5C13.51 14.77 13.28 15 13.01 15ZM18.75 14.15C18.67 14.28 18.19 15 16.99 15C16.96 15 15.76 15.06 15.16 14.02C15.01 13.76 15.12 13.42 15.4 13.31L15.52 13.26C15.74 13.17 15.99 13.25 16.11 13.45C16.25 13.69 16.5 13.94 16.99 13.94C17.4 13.94 17.88 13.66 17.88 13.17C17.88 12.62 17.4 12.38 16.84 12.38C16.57 12.38 16.35 12.15 16.35 11.88C16.35 11.62 16.55 11.41 16.8 11.39V11.38C17.13 11.38 17.68 11.24 17.68 10.66C17.68 10.27 17.37 10.01 16.93 10.01C16.61 10.01 16.4 10.14 16.26 10.31C16.12 10.49 15.89 10.57 15.68 10.48L15.6 10.45C15.3 10.33 15.2 9.95 15.4 9.7C15.67 9.35 16.16 9 16.94 9C18.03 9 18.48 9.64 18.56 9.75C18.89 10.25 18.84 10.91 18.58 11.32C18.43 11.54 18.26 11.7 18.06 11.8V11.87C18.34 11.98 18.57 12.15 18.74 12.39C19.11 12.91 19.07 13.66 18.75 14.15Z" />
  </Icon>
);
