import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useDeleteMeMutation } from 'generated/rbi-graphql';
import { useLogger } from 'state/logger';
import { routes } from 'utils/routing';

export const useAccountDelete = () => {
  const logger = useLogger();
  const [deleteMe, { called, loading, error, data }] = useDeleteMeMutation();

  const isRejected = !!error || (called && !loading && !data?.deleteMe);
  const navigate = useNavigate();

  const handleAccountDelete = async () => {
    try {
      await deleteMe();
    } catch {
      // error handled in useEffect below
      return;
    }
  };

  const handleCancel = () => {
    navigate('../');
  };

  useEffect(() => {
    if (isRejected) {
      logger.error({ error, message: 'Failed to delete user.' });
      return;
    }

    if (data?.deleteMe) {
      navigate(routes.signOut);
    }
  }, [isRejected, data, logger, error, navigate]);

  return {
    handleAccountDelete,
    handleCancel,
    isLoading: loading,
    isRejected,
  };
};
