import React from 'react';

import { Icon } from 'native-base';
import { ClipPath, Defs, Path, Rect } from 'react-native-svg';

import { ISVGProps } from './types';

export const masterpass = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Rect y="4" width="24" height="16" rx="2" fill="white" />
    <Rect
      x="0.5"
      y="4.5"
      width="23"
      height="15"
      rx="1.5"
      stroke="black"
      strokeOpacity="0.3"
      fill="white"
    />
    <Path d="M4.5 7.5H19.5V16.5H4.5V7.5Z" fill="white" />
    <Path
      d="M19.3768 11.7123L15.7829 7.70589C15.7071 7.58712 15.5701 7.50779 15.4137 7.50779C15.1752 7.50779 14.9818 7.69223 14.9818 7.91984C14.9818 8.03976 15.0356 8.14773 15.1213 8.22305L18.3771 11.9886L15.1575 15.6826H14.8064H13.7666L16.8093 12.305C16.8112 12.3029 16.813 12.3008 16.8149 12.2987L16.8156 12.299C16.9003 12.2101 16.938 12.1062 16.936 12.0039C16.938 11.9016 16.9003 11.7977 16.8156 11.7088L16.8149 11.709C16.813 11.7069 16.8112 11.7048 16.8093 11.7028L13.1759 7.71242C13.1751 7.71148 13.1743 7.71055 13.1735 7.70962C13.0982 7.58876 12.96 7.50779 12.802 7.50779H10.2503C10.0117 7.50779 9.81836 7.69223 9.81836 7.91984C9.81836 7.98891 9.82825 8.06072 9.86781 8.11125L13.2495 12.0192L10.3673 15.318C10.3502 15.3344 10.1018 15.6721 9.66517 15.6721H6.22301C5.75488 15.6721 5.36668 15.3235 5.36668 14.8606V9.14206C5.36668 8.73906 5.75488 8.32514 6.23359 8.32514H8.37504C8.62748 8.32514 8.80694 8.16067 8.80694 7.91984C8.80694 7.67901 8.62748 7.5 8.37504 7.5L6.2126 7.50817C5.18819 7.50817 4.5 8.31423 4.5 9.13367V14.8606C4.5 15.7429 5.31533 16.4944 6.2126 16.4944H9.65378C9.82733 16.4944 9.98609 16.4702 10.129 16.4313C10.1495 16.4258 10.1696 16.4199 10.1893 16.4138C10.6638 16.2671 10.9501 15.9657 11.0056 15.9019L14.2492 12.2954C14.4026 12.1293 14.423 11.8885 14.2517 11.7088L14.2511 11.709C14.2492 11.7069 14.2474 11.7048 14.2455 11.7028L11.1773 8.32514H12.2426H12.5937L15.8 12.0039L12.4259 15.8868C12.4248 15.888 12.4269 15.8913 12.4317 15.8965C12.4002 15.9538 12.3822 16.0188 12.3822 16.0879C12.3822 16.3155 12.5756 16.5 12.8141 16.5H15.3659C15.5239 16.5 15.662 16.4189 15.7373 16.2981C15.7381 16.2972 15.7389 16.2963 15.7397 16.2954L19.3732 12.305C19.3751 12.3029 19.3768 12.3008 19.3787 12.2987L19.3794 12.299C19.5506 12.1192 19.5303 11.8784 19.3768 11.7123Z"
      fill="black"
    />
    <Defs>
      <ClipPath id="clip0_1731_18286">
        <Rect width="15" height="9" fill="white" transform="translate(4.5 7.5)" />
      </ClipPath>
    </Defs>
  </Icon>
);
