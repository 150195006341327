/* eslint-disable max-lines */
import React from 'react';

import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

import { ISVGProps } from './types';

export const thUsFlag = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 20 11">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10.4612H19.8994V0.0393219H0V10.4612Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.824542H19.8829V0.00012207H0V0.824542Z"
      fill="#C8102E"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.4371H19.8829V1.6127H0V2.4371Z"
      fill="#C8102E"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 4.0497H19.8829V3.22528H0V4.0497Z"
      fill="#C8102E"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 5.66226H19.8829V4.83784H0V5.66226Z"
      fill="#C8102E"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 7.27484H19.8829V6.45041H0V7.27484Z"
      fill="#C8102E"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 8.88741H19.8829V8.063H0V8.88741Z"
      fill="#C8102E"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10.4994H19.8829V9.67557H0V10.4994Z"
      fill="#C8102E"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 5.66246H7.94054V-1.52588e-05H0V5.66246Z"
      fill="#3C3B6E"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.674805 0.216827L0.749115 0.447967H0.989605L0.795035 0.590817L0.869365 0.821967L0.674805 0.679117L0.480245 0.821967L0.554555 0.590817L0.359985 0.447967H0.600475L0.674805 0.216827Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.32932 0.797836L1.40363 1.02899H1.64412L1.44957 1.17184L1.52388 1.40298L1.32932 1.26014L1.13476 1.40298L1.20907 1.17184L1.01453 1.02899H1.25502L1.32932 0.797836Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.32932 1.93304L1.40363 2.16419H1.64412L1.44957 2.30704L1.52388 2.53818L1.32932 2.39534L1.13476 2.53818L1.20907 2.30704L1.01453 2.16419H1.25502L1.32932 1.93304Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.32932 3.06825L1.40363 3.2994H1.64412L1.44957 3.44225L1.52388 3.67339L1.32932 3.53055L1.13476 3.67339L1.20907 3.44225L1.01453 3.2994H1.25502L1.32932 3.06825Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.32932 4.20346L1.40363 4.43461H1.64412L1.44957 4.57746L1.52388 4.80861L1.32932 4.66576L1.13476 4.80861L1.20907 4.57746L1.01453 4.43461H1.25502L1.32932 4.20346Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.65601 0.797836L2.73031 1.02899H2.9708L2.77624 1.17184L2.85057 1.40298L2.65601 1.26014L2.46144 1.40298L2.53575 1.17184L2.34119 1.02899H2.58168L2.65601 0.797836Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.65601 1.93304L2.73031 2.16419H2.9708L2.77624 2.30704L2.85057 2.53818L2.65601 2.39534L2.46144 2.53818L2.53575 2.30704L2.34119 2.16419H2.58168L2.65601 1.93304Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.65601 3.06825L2.73031 3.2994H2.9708L2.77624 3.44225L2.85057 3.67339L2.65601 3.53055L2.46144 3.67339L2.53575 3.44225L2.34119 3.2994H2.58168L2.65601 3.06825Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.65601 4.20346L2.73031 4.43461H2.9708L2.77624 4.57746L2.85057 4.80861L2.65601 4.66576L2.46144 4.80861L2.53575 4.57746L2.34119 4.43461H2.58168L2.65601 4.20346Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.98279 0.797836L4.0571 1.02899H4.29758L4.10302 1.17184L4.17735 1.40298L3.98279 1.26014L3.78823 1.40298L3.86253 1.17184L3.66797 1.02899H3.90846L3.98279 0.797836Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.98279 1.93304L4.0571 2.16419H4.29758L4.10302 2.30704L4.17735 2.53818L3.98279 2.39534L3.78823 2.53818L3.86253 2.30704L3.66797 2.16419H3.90846L3.98279 1.93304Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.98279 3.06825L4.0571 3.2994H4.29758L4.10302 3.44225L4.17735 3.67339L3.98279 3.53055L3.78823 3.67339L3.86253 3.44225L3.66797 3.2994H3.90846L3.98279 3.06825Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.98279 4.20346L4.0571 4.43461H4.29758L4.10302 4.57746L4.17735 4.80861L3.98279 4.66576L3.78823 4.80861L3.86253 4.57746L3.66797 4.43461H3.90846L3.98279 4.20346Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.30942 0.797836L5.38375 1.02899H5.62424L5.42968 1.17184L5.50398 1.40298L5.30942 1.26014L5.11488 1.40298L5.18919 1.17184L4.99463 1.02899H5.23511L5.30942 0.797836Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.30942 1.93304L5.38375 2.16419H5.62424L5.42968 2.30704L5.50398 2.53818L5.30942 2.39534L5.11488 2.53818L5.18919 2.30704L4.99463 2.16419H5.23511L5.30942 1.93304Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.30942 3.06825L5.38375 3.2994H5.62424L5.42968 3.44225L5.50398 3.67339L5.30942 3.53055L5.11488 3.67339L5.18919 3.44225L4.99463 3.2994H5.23511L5.30942 3.06825Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.30942 4.20346L5.38375 4.43461H5.62424L5.42968 4.57746L5.50398 4.80861L5.30942 4.66576L5.11488 4.80861L5.18919 4.57746L4.99463 4.43461H5.23511L5.30942 4.20346Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.6362 0.797836L6.71053 1.02899H6.951L6.75646 1.17184L6.83076 1.40298L6.6362 1.26014L6.44166 1.40298L6.51597 1.17184L6.32141 1.02899H6.5619L6.6362 0.797836Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.6362 1.93304L6.71053 2.16419H6.951L6.75646 2.30704L6.83076 2.53818L6.6362 2.39534L6.44166 2.53818L6.51597 2.30704L6.32141 2.16419H6.5619L6.6362 1.93304Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.6362 3.06825L6.71053 3.2994H6.951L6.75646 3.44225L6.83076 3.67339L6.6362 3.53055L6.44166 3.67339L6.51597 3.44225L6.32141 3.2994H6.5619L6.6362 3.06825Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.6362 4.20346L6.71053 4.43461H6.951L6.75646 4.57746L6.83076 4.80861L6.6362 4.66576L6.44166 4.80861L6.51597 4.57746L6.32141 4.43461H6.5619L6.6362 4.20346Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.674805 1.35204L0.749115 1.58318H0.989605L0.795035 1.72603L0.869365 1.95718L0.674805 1.81433L0.480245 1.95718L0.554555 1.72603L0.359985 1.58318H0.600475L0.674805 1.35204Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.674805 2.48724L0.749115 2.71838H0.989605L0.795035 2.86123L0.869365 3.09238L0.674805 2.94953L0.480245 3.09238L0.554555 2.86123L0.359985 2.71838H0.600475L0.674805 2.48724Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.674805 3.62245L0.749115 3.85359H0.989605L0.795035 3.99644L0.869365 4.22759L0.674805 4.08475L0.480245 4.22759L0.554555 3.99644L0.359985 3.85359H0.600475L0.674805 3.62245Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.674805 4.75766L0.749115 4.9888H0.989605L0.795035 5.13165L0.869365 5.3628L0.674805 5.21996L0.480245 5.3628L0.554555 5.13165L0.359985 4.9888H0.600475L0.674805 4.75766Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.98387 0.216827L2.05817 0.447967H2.29868L2.10412 0.590817L2.17843 0.821967L1.98387 0.679117L1.78931 0.821967L1.86363 0.590817L1.66907 0.447967H1.90956L1.98387 0.216827Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.98387 1.35204L2.05817 1.58318H2.29868L2.10412 1.72603L2.17843 1.95718L1.98387 1.81433L1.78931 1.95718L1.86363 1.72603L1.66907 1.58318H1.90956L1.98387 1.35204Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.98387 2.48724L2.05817 2.71838H2.29868L2.10412 2.86123L2.17843 3.09238L1.98387 2.94953L1.78931 3.09238L1.86363 2.86123L1.66907 2.71838H1.90956L1.98387 2.48724Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.98387 3.62245L2.05817 3.85359H2.29868L2.10412 3.99644L2.17843 4.22759L1.98387 4.08475L1.78931 4.22759L1.86363 3.99644L1.66907 3.85359H1.90956L1.98387 3.62245Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.98387 4.75766L2.05817 4.9888H2.29868L2.10412 5.13165L2.17843 5.3628L1.98387 5.21996L1.78931 5.3628L1.86363 5.13165L1.66907 4.9888H1.90956L1.98387 4.75766Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.32823 0.216827L3.40255 0.447967H3.64302L3.44848 0.590817L3.52279 0.821967L3.32823 0.679117L3.13368 0.821967L3.20799 0.590817L3.01343 0.447967H3.25392L3.32823 0.216827Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.32823 1.35204L3.40255 1.58318H3.64302L3.44848 1.72603L3.52279 1.95718L3.32823 1.81433L3.13368 1.95718L3.20799 1.72603L3.01343 1.58318H3.25392L3.32823 1.35204Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.32823 2.48724L3.40255 2.71838H3.64302L3.44848 2.86123L3.52279 3.09238L3.32823 2.94953L3.13368 3.09238L3.20799 2.86123L3.01343 2.71838H3.25392L3.32823 2.48724Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.32823 3.62245L3.40255 3.85359H3.64302L3.44848 3.99644L3.52279 4.22759L3.32823 4.08475L3.13368 4.22759L3.20799 3.99644L3.01343 3.85359H3.25392L3.32823 3.62245Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.32823 4.75766L3.40255 4.9888H3.64302L3.44848 5.13165L3.52279 5.3628L3.32823 5.21996L3.13368 5.3628L3.20799 5.13165L3.01343 4.9888H3.25392L3.32823 4.75766Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.64611 0.216827L4.72042 0.447967H4.96091L4.76635 0.590817L4.84065 0.821967L4.64611 0.679117L4.45155 0.821967L4.52586 0.590817L4.3313 0.447967H4.57179L4.64611 0.216827Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.64611 1.35204L4.72042 1.58318H4.96091L4.76635 1.72603L4.84065 1.95718L4.64611 1.81433L4.45155 1.95718L4.52586 1.72603L4.3313 1.58318H4.57179L4.64611 1.35204Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.64611 2.48724L4.72042 2.71838H4.96091L4.76635 2.86123L4.84065 3.09238L4.64611 2.94953L4.45155 3.09238L4.52586 2.86123L4.3313 2.71838H4.57179L4.64611 2.48724Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.64611 3.62245L4.72042 3.85359H4.96091L4.76635 3.99644L4.84065 4.22759L4.64611 4.08475L4.45155 4.22759L4.52586 3.99644L4.3313 3.85359H4.57179L4.64611 3.62245Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.64611 4.75766L4.72042 4.9888H4.96091L4.76635 5.13165L4.84065 5.3628L4.64611 5.21996L4.45155 5.3628L4.52586 5.13165L4.3313 4.9888H4.57179L4.64611 4.75766Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.96398 0.216827L6.03829 0.447967H6.27878L6.08422 0.590817L6.15855 0.821967L5.96398 0.679117L5.76942 0.821967L5.84373 0.590817L5.64917 0.447967H5.88966L5.96398 0.216827Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.96398 1.35204L6.03829 1.58318H6.27878L6.08422 1.72603L6.15855 1.95718L5.96398 1.81433L5.76942 1.95718L5.84373 1.72603L5.64917 1.58318H5.88966L5.96398 1.35204Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.96398 2.48724L6.03829 2.71838H6.27878L6.08422 2.86123L6.15855 3.09238L5.96398 2.94953L5.76942 3.09238L5.84373 2.86123L5.64917 2.71838H5.88966L5.96398 2.48724Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.96398 3.62245L6.03829 3.85359H6.27878L6.08422 3.99644L6.15855 4.22759L5.96398 4.08475L5.76942 4.22759L5.84373 3.99644L5.64917 3.85359H5.88966L5.96398 3.62245Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.96398 4.75766L6.03829 4.9888H6.27878L6.08422 5.13165L6.15855 5.3628L5.96398 5.21996L5.76942 5.3628L5.84373 5.13165L5.64917 4.9888H5.88966L5.96398 4.75766Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.30832 0.216827L7.38265 0.447967H7.62314L7.42857 0.590817L7.50288 0.821967L7.30832 0.679117L7.11378 0.821967L7.18809 0.590817L6.99353 0.447967H7.23402L7.30832 0.216827Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.30832 1.35204L7.38265 1.58318H7.62314L7.42857 1.72603L7.50288 1.95718L7.30832 1.81433L7.11378 1.95718L7.18809 1.72603L6.99353 1.58318H7.23402L7.30832 1.35204Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.30832 2.48724L7.38265 2.71838H7.62314L7.42857 2.86123L7.50288 3.09238L7.30832 2.94953L7.11378 3.09238L7.18809 2.86123L6.99353 2.71838H7.23402L7.30832 2.48724Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.30832 3.62245L7.38265 3.85359H7.62314L7.42857 3.99644L7.50288 4.22759L7.30832 4.08475L7.11378 4.22759L7.18809 3.99644L6.99353 3.85359H7.23402L7.30832 3.62245Z"
      fill="white"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.30832 4.75766L7.38265 4.9888H7.62314L7.42857 5.13165L7.50288 5.3628L7.30832 5.21996L7.11378 5.3628L7.18809 5.13165L6.99353 4.9888H7.23402L7.30832 4.75766Z"
      fill="white"
    />
  </Icon>
);
