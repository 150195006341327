import React from 'react';

import { Icon } from 'native-base';
import { Path, Rect } from 'react-native-svg';

import { ISVGProps } from './types';

export const amex = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Rect y="4" width="24" height="16" rx="2" fill="#1F72CD" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.03145 8.86951L3 14.0802H5.43193L5.73341 13.2494H6.42255L6.72404 14.0802H9.4009V13.4461L9.63943 14.0802H11.0241L11.2626 13.4327V14.0802H16.8298L17.5067 13.271L18.1406 14.0802L21 14.0869L18.9621 11.4894L21 8.86951H18.1849L17.526 9.66378L16.9121 8.86951H10.8557L10.3357 10.2144L9.80343 8.86951H7.37658V9.48202L7.10661 8.86951H5.03145ZM5.50174 9.60944H6.68717L8.03463 13.1428V9.60944H9.33323L10.374 12.1429L11.3332 9.60944H12.6253V13.3485H11.839L11.8326 10.4186L10.6864 13.3485H9.98309L8.83044 10.4186V13.3485H7.21301L6.90638 12.5102H5.24975L4.94375 13.3477H4.07715L5.50174 9.60944ZM16.5421 9.60944H13.3452V13.3463H16.4926L17.5071 12.1078L18.4849 13.3463H19.507L18.0214 11.4887L19.507 9.60944H18.5292L17.5199 10.8337L16.5421 9.60944ZM6.079 10.242L5.5332 11.7353H6.62416L6.079 10.242ZM14.1344 11.0662V10.3836V10.3829H16.1291L16.9995 11.4745L16.0906 12.5721H14.1344V11.8269H15.8784V11.0662H14.1344Z"
      fill="white"
    />
  </Icon>
);
