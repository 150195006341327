import React from 'react';

import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

import { ISVGProps } from './types';

export const plkRewardsPoints = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.884766 12C0.884766 5.9 5.78477 1 11.8848 1C17.8848 1 22.8848 5.9 22.8848 12C22.8848 18.1 17.9848 23 11.8848 23C5.78477 23 0.884766 18.1 0.884766 12ZM3.48476 11.9C3.48476 16.6 7.28477 20.3 11.8848 20.3C16.4848 20.3 20.2848 16.6 20.2848 11.9C20.2848 7.2 16.5848 3.5 11.8848 3.5C7.18477 3.5 3.48476 7.2 3.48476 11.9ZM12.1641 7.58069C11.8282 7.66304 11.1985 7.75466 10.7311 7.57835C10.2024 7.37894 9.76238 6.94713 9.84341 6.235C9.91667 5.59114 10.464 5.33946 10.9354 5.45729C11.3568 5.56264 11.5006 5.99467 11.5006 5.99467C11.5006 5.99467 11.7417 5.58661 12.247 5.63044C12.7952 5.67799 13.0945 6.12107 13.2205 6.3694L15.0215 5.88845L14.5116 6.84049L15.4381 6.95262L14.5755 7.79697C14.8801 7.97976 15.0341 8.31045 14.9043 8.63098C14.8387 8.7932 14.6967 8.87836 14.5726 8.9229C14.8998 9.5455 15.7628 11.221 16.2181 12.4224C17.3616 15.4388 15.0572 17.3289 13.1662 17.72L13.0348 18.5706H12.0385L11.9173 17.7882C11.7319 17.7747 11.5473 17.7512 11.3655 17.7174C10.2251 17.5056 9.16951 16.8909 8.71336 16.1568C8.35501 15.5798 8.13572 14.8534 8.22206 13.9553C8.23689 13.7961 8.27546 13.6107 8.31683 13.412L8.32149 13.3896C8.46268 12.7102 8.63427 11.8846 8.06696 11.4272C7.32657 10.8302 6.726 11.7199 6.726 11.7199L5.60281 10.3534C7.32045 8.2628 8.80811 8.34368 9.6356 9.08086C10.4148 9.77496 10.2907 10.5581 10.1441 11.4836C10.135 11.541 10.1258 11.599 10.1168 11.6575C10.0107 12.3429 10.3168 12.8092 10.7796 12.8843C11.2847 12.9662 11.701 12.7189 11.9326 12.2407C12.1868 11.7161 12.1761 11.0059 12.1655 10.2988V10.2988L12.1655 10.2984C12.1643 10.2229 12.1632 10.1474 12.1624 10.0722C12.1609 9.94204 12.1601 9.8188 12.1601 9.70556C12.1608 9.42171 12.1616 9.00653 12.1623 8.59136L12.1623 8.58889L12.1624 8.52547L12.1641 7.58069Z"
      fill="#911987"
    />
  </Icon>
);
