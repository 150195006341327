import React from 'react';

import { Icon } from 'native-base';
import { Path } from 'react-native-svg';

import { ISVGProps } from './types';

export const plkRewardsProgress = (props: ISVGProps) => (
  <Icon {...props} viewBox="0 0 24 24">
    <Path d="M14.568 22.9077C18.568 22.2077 21.768 17.8077 21.368 11.7077C20.968 5.70771 16.768 1.10771 12.268 1.00771C10.468 0.907713 8.168 1.80772 7.568 2.10772C3.868 3.60772 1.568 8.10772 2.068 13.1077C2.568 18.9077 6.968 23.1077 11.068 23.1077C11.868 23.0077 13.668 23.0077 14.568 22.9077Z" />
    <Path
      d="M14.2346 21.2084C18.1131 20.632 20.6451 16.0463 19.8901 10.9661C19.1351 5.88582 15.3789 2.23474 11.5004 2.81116C7.62192 3.38758 5.08985 7.97322 5.84488 13.0535C6.59991 18.1337 10.3561 21.7848 14.2346 21.2084Z"
      fill="white"
    />
    <Path d="M11.316 7.60336C11.802 7.71336 12.4 7.51648 12.7134 7.37635C12.7577 7.64969 12.8156 8.00588 12.8744 8.36718L12.8852 8.43369C12.9561 8.86995 13.0271 9.3065 13.0754 9.60481C13.0949 9.72353 13.1169 9.85262 13.1408 9.98886C13.1545 10.0677 13.1687 10.1468 13.1828 10.2259C13.3149 10.9655 13.4477 11.7084 13.2903 12.2992C13.1468 12.8377 12.7835 13.1636 12.277 13.1586C11.8129 13.1539 11.4342 12.7141 11.4196 11.9785C11.4184 11.9157 11.4173 11.8535 11.4163 11.7918C11.3998 10.798 11.3859 9.95705 10.5068 9.35408C9.57315 8.71369 8.10897 8.86711 6.79455 11.334L8.12483 12.5869C8.12483 12.5869 8.55708 11.5579 9.38166 12.0652C10.0135 12.454 9.98839 13.3471 9.96775 14.082L9.96706 14.1062C9.96097 14.3212 9.95528 14.5217 9.96824 14.691C10.0388 15.6465 10.3776 16.373 10.8263 16.9205C11.3974 17.6171 12.5323 18.0926 13.6805 18.132C13.8636 18.1383 14.0476 18.1334 14.2306 18.1179L14.4835 18.9188L15.4548 18.7593L15.4364 17.8464C17.2125 17.1336 19.1334 14.7829 17.4992 11.8035C16.8485 10.6167 15.7187 8.99828 15.2925 8.39791C15.4057 8.33135 15.5295 8.21933 15.5656 8.03873C15.6368 7.6819 15.4298 7.35984 15.1014 7.21697L15.7969 6.1936L14.8743 6.2244L15.2075 5.14457L13.5346 5.93721C13.369 5.69702 13.0009 5.28041 12.4583 5.31833C11.9582 5.35329 11.7934 5.81974 11.7934 5.81974C11.7934 5.81974 11.5788 5.3898 11.1498 5.34683C10.67 5.29877 10.1798 5.65029 10.2193 6.33707C10.2629 7.09668 10.7663 7.47894 11.316 7.60336Z" />
  </Icon>
);
